@use "styles/variables" as *;

// ? In some cases You may need to override border color of dropdowns and date-range picker
// ? In such cases use these classes to override

.Dropdown {
  // ! Access direct decendent to change border color
  > div {
    border-color: $button-selected-color;

    &:hover {
      border-color: $button-selected-color;
    }
  }
}

.Date_Picker {
  // ! Access direct decendent to change border color
  > input {
    border-color: $button-selected-color;

    &:hover {
      border-color: $button-selected-color;
    }
  }
}
