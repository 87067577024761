@use "styles/utilities" as *;
@use "styles/variables" as *;

.Export_Pill_Section {
  flex: 4.1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 25px;
  overflow: auto;
  border-bottom: 2px solid $button-selected-color;
}

.Export_Details_Pills_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding-left: 35px;
  padding-bottom: 20px;
  padding-top: 10px;

  span {
    max-width: 170px;
    @extend .text_ellipsis;
  }
}
