@use "styles/colors" as *;
@use "styles/fonts" as *;
@use "styles/variables" as *;
@use "styles/mixins" as *;

// * Override default react tooltip styles
.Tooltip {
  border-radius: $spacer !important;
  opacity: 1 !important;
  padding: 5px $spacer !important;
  box-shadow: 0px 8px 16px #58b5ff26;
  max-width: 300px;
}
