@use "styles/variables" as *;

.Record_Detail_Title {
  padding: 1rem;
}

.Title_Container {
  background-color: $bg-icon-color;
  border-radius: 4px;
  border: 1px solid $bg-navbar-color;
  padding: 1.2rem 0.8rem 0.8rem;
  margin-right: 0.5rem;
}
