@use "styles/variables" as *;
@use "styles/colors" as *;

.HyperLink_Div {
  text-decoration: none;
  
  span::after {
    content: "";
    border-bottom: 1px solid $font-link-color;
    width: 100%;
    display: block;
    background-color: $font-link-color;
    opacity: 0;
    top: -4px;
    position: relative;
  }
  &:hover,
  &:focus {
    color: $font-link-color;
    span::after {
      opacity: 1;
    }
  }
  &[disabled] {
    pointer-events: none;
    // cursor: not-allowed;
    @extend .Font_Disabled_Color;

    img {
      filter: grayscale(1);
    }
  }
}
