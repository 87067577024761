.Generate_Detail_Report_Modal {
  padding: 20px;
}

.Export_Report_Actions {
  padding: 20px 0 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1.8fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: "Export_Format Export_Format_Dropdown Modal_Buttons";
  align-items: center;
}

.Export_Format {
  grid-area: Export_Format;
}

.Export_Format_Dropdown {
  grid-area: Export_Format_Dropdown;
}

.Modal_Buttons {
  grid-area: Modal_Buttons;
  display: flex;
  justify-content: flex-end;
}
