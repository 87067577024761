.Bookmark_Details_Container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 1.9fr 0.1fr;
  gap: 0px 0px;
  min-height: inherit;

  padding: 20px 30px 14px 40px;
  min-width: 600px;
}
