@use "styles/variables" as *;

.List_Scroll_Table {
  overflow-x: auto;
}

.Table_Height1 {
  height: calc(100vh - 11.6rem);
}

.Table_Height2 {
  height: calc(100vh - 19.3rem);
}

.Table_Height3 {
  height: calc(100vh - 16.4rem);
}
