@use "styles/variables" as *;
@use "styles/colors" as *;
@use "styles/fonts" as *;

$expandable-box-width: 700px;

.Archive_Raw_Data_Expandable_Box_Header {
  display: flex;
  align-content: center;
  justify-content: space-between;
  border: 2px solid $bg-navbar-color;
  border-radius: 10px;
  width: $expandable-box-width;
  margin: 2rem auto;
  padding: 1rem 1.5rem;
  transition: all 0.3s ease;
  user-select: none;

  svg {
    transition: all 0.3s ease;
  }

  @extend .Font_H5;
  @extend .Font_Title_Color;
  @extend .Bg_Card_Color;

  &:hover {
    background-color: lighten($bg-card-color, 5);
  }
}

.Disabled_Header {
  cursor: not-allowed;

  @extend .Font_Disabled_Color;

  &:hover {
    background-color: $bg-card-color;
  }

  svg {
    opacity: 50%;
  }
}

.Archive_Raw_Data_Body_Wrapper {
  width: $expandable-box-width;
  user-select: none;

  .Original_Data_Container {
    border-radius: 10px;
    width: inherit;
    margin: 0 0 36px 0;
    min-height: auto;

    @extend .Bg_Card_Color;
  }

  .Archive_Raw_Data_Body_Key_Value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    margin-inline: 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid fade-out($button-selected-color, 0.5);
    }
  }
}
