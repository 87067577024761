@use "styles/colors" as *;
@use "styles/variables" as *;

.Footer {
  min-height: $spacer * 9;
  @extend .Bg_Card_Color;

  box-shadow: inset 0px 1px 0px $bg-icon-color;

  display: flex;
  align-items: center;

  .Footer_Buttons {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
