.Equal_Widht_Flex {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.Expanded_Content {
  background-color: #001628;
}

.Expanded_Content_Item_key {
  flex-grow: 2;
  flex-basis: 0;
  margin-left: 5px;
}

.Expanded_Content_Item_Values {
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  padding-right: 25px;
}

.Invisible_SVG {
  visibility: hidden;
}
