@use "fonts" as *;
@use "colors" as *;
@use "variables" as *;

.recharts-surface {
  .recharts-tooltip-cursor {
    stroke: $bg-icon-color;
  }
}

.recharts-cartesian-axis {
  text {
    fill: $font-body-color;
  }

  line {
    stroke: #004780;
  }
}

.recharts-cartesian-axis-tick {
  @extend .Font_H12;
  @extend .Font_Body_Color;

  text {
    fill: $font-body-color;
  }
}

.recharts-default-legend {
  padding-left: 2rem !important;

  .recharts-legend-item {
    margin-right: 20px !important;

    span {
      vertical-align: middle;
    }
  }
}
