@use "styles/variables" as *;

.Nav_Wrapper {
  min-height: 60px;
  padding-inline: $spacer * 4;
}

.Nav_Items_List {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacer * 3;
  padding: 0;
  margin: 0;
}
