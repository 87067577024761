@use "styles/variables" as *;
@use "styles/colors" as *;

.Export_Detail_Left_Section {
  flex: 1.7;
  display: flex;
  flex-direction: column;
  border-top: 2px solid $button-selected-color;
  border-right: 2px solid $button-selected-color;

  .Footer {
    min-height: $spacer * 9;
    @extend .Bg_Card_Color;

    box-shadow: inset 0px 1px 0px $bg-icon-color;
  }
}
