@use "styles/fonts" as *;
@use "styles/colors" as *;
@use "styles/variables" as *;

.Breadcrumbs_Wrapper {
  min-height: $breadcrumbs-bar-height;
  padding-inline: $spacer * 3;
  display: flex;
  align-items: center;
}

.Breadcrumbs_Title {
  @extend .Font_H5;
  text-decoration: none;
  color: $font-subtitle-color;
  padding-inline: $spacer;

  &:hover {
    color: $font-link-color;
  }
}

.Breadcrumbs_Active {
  color: $info-font-color;
  cursor: default;
  pointer-events: none;

  &:hover {
    color: $info-font-color;
  }
}
