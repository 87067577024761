@use "styles/_colors" as *;
@use "styles/variables" as *;

.Filter_And_Group_By_Container {
  height: calc(100vh - 140px - #{$breadcrumbs-bar-height});
  overflow-y: auto;
}

.Search_Footer {
  @extend .Bg_Card_Color;
  box-shadow: inset 0px 1px 0px #003560;
  min-height: 80px;
  width: 100%;

  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
}

.Footer_Buttons {
  bottom: -24px;
  position: relative;
  right: 32px;
}
