@use "styles/variables" as *;

.Box {
  background-color: $bg-icon-color;
  border-radius: 4px;
  border: 1px solid $bg-navbar-color;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-inline: 0.7rem;
}
