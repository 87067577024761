@use "styles/variables" as *;
@use "styles/colors" as *;

.List_Top_Section_Container {
  display: flex;
  align-items: center;
  padding: $spacer * 2;

  @extend .Bg_Card_Color;
}
