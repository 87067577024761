@use "styles/variables" as *;
@use "styles/colors" as *;

.Table_Header {
  color: $font-title-color;
}

.Table_Header_Non_Clickable {
  pointer-events: none;
}

.Table_Title {
  flex-grow: 1;
}

.SortingIcon {
  height: 20px;
  width: 20px;
}

.Table_Header_Sticky {
  position: sticky;
  top: 0;
  border-inline: 0px !important;

  @extend .Bg_Card_Color;
}
