@use "styles/colors" as *;
@use "styles/fonts" as *;
@use "styles/variables" as *;

.Switch_Container {
  display: flex;
  overflow: hidden;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked + label {
      @extend .Bg_Navbar_Color;
      @extend .Font_H7;
      @extend .Font_Title_Color;

      border: 1px solid $bg-icon-color;
      box-shadow: none;
    }
  }

  label {
    @extend .Bg_Card_Color;
    @extend .Font_H8;
    @extend .Font_Body_Color;

    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    border: 1px solid lighten($bg-card-color, 4);
    transition: all 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
    }

    &:first-of-type {
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
      border-radius: 0 8px 8px 0;
    }
  }
}

.Disabled_Switch_Container {
  cursor: not-allowed;

  input:checked + label {
    color: $font-disabled-color;
  }

  label {
    pointer-events: none;
  }
}
