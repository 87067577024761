.Filters {
  grid-area: Filters;
}

.Filters_Pills {
  grid-area: Filters_Pills;
  padding-top: 5px;
}

.Group_By {
  grid-area: Group_By;
}

.Group_By_Pills {
  padding-top: 5px;
  grid-area: Group_By_Pills;
}
