@use "styles/colors" as *;
@use "styles/fonts" as *;
@use "styles/variables" as *;
@use "styles/mixins" as *;
@use "styles/utilities" as *;

.Btn {
  border-radius: 6px;
  user-select: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

//******* Button Designs ********//

.Primary_Btn {
  @extend .Button_Primary_Color;
  @include transition(all, $button-hover-duration);

  //TODO: Add mixin to check isloading and add styles

  //******Is Loading******//
  &[isloading="true"] {
    transition: none;
    color: $bg-dark-base-color;

    @extend .Button_Primary_Color;
    @extend .cursor-not-allowed;
  }

  //******Hover and Active******//
  &:hover:enabled:not([isloading="true"]),
  &:active:enabled:not([isloading="true"]) {
    color: $bg-dark-base-color;

    @extend .Button_Hover_Color;
  }

  //******Disabled******//
  &:disabled,
  &[disabled] {
    transition: none;
    background-color: $icon-disabled-color;

    @extend .Font_Disabled_Color;
    @extend .cursor-not-allowed;
  }
}

.Secondary_Btn {
  color: $button-primary-color;
  background-color: transparent;

  @include border-1-solid($button-primary-color);
  @include transition(all, $button-hover-duration);

  //******Is Loading******//
  &[isloading="true"] {
    transition: none;
    color: $icon-primary-color;

    @include border-1-solid($bg-navbar-color);
    @extend .Bg_Card_Color;
    @extend .cursor-not-allowed;
  }

  //******Hover******//
  &:hover:enabled:not([isloading="true"]) {
    background-color: $icon-disabled-color;
    color: $icon-primary-color;

    @include border-1-solid($button-hover-color);
  }

  //******Active******//
  &:active:enabled:not([isloading="true"]) {
    color: $icon-primary-color;

    @include border-1-solid($button-hover-color);
    @extend .Bg_Light_Base_Color;
  }

  //******Disabled******//
  &:disabled,
  &[disabled] {
    transition: none;

    @extend .Bg_Card_Color;
    @include border-1-solid(#004780);
    @extend .Font_Disabled_Color;
    @extend .cursor-not-allowed;
  }
}

//TODO: Add styles whenever required
.Danger_Btn {
  background-color: $button-danger-color;
  color: $font-button-color;

  &:hover:enabled {
    background-color: darken($color: $button-danger-color, $amount: 10%);
  }

  &:disabled,
  &[disabled] {
    background-color: transparent;
    border: 1px solid lighten($button-danger-color, $amount: 10%);
    color: darken($button-danger-color, $amount: 10%);

    @extend .Font_Disabled_Color;
    @extend .cursor-not-allowed;
  }
}

//TODO: Add styles whenever required
.Ghost_Btn {
}

//TODO: Add styles whenever required
.Ghost_Danger_Btn {
}

//******* Button Sizes ********//

.Large_Btn {
  @extend .Font_H7;
  padding: 10px map-get($map: $spacers, $key: 2);
}

.Small_Btn {
  @extend .Font_H9;
  padding: 6px map-get($map: $spacers, $key: 2);
}

//******* Button Content ********//
%Horizontal_Center {
  display: flex;
  justify-content: center;
}

.Text_Btn {
  &[isloading="true"].Large_Btn {
    min-width: $spacer * 12;
    @extend %Horizontal_Center;
  }

  &[isloading="true"].Small_Btn {
    min-width: $spacer * 11;
    @extend %Horizontal_Center;
  }
}

.Icon_Text_Btn {
  display: flex;
  align-items: center;
  gap: $spacer;

  &[isloading="true"].Large_Btn {
    min-width: $spacer * 16;
    @extend %Horizontal_Center;
  }

  &[isloading="true"].Small_Btn {
    min-width: $spacer * 13.5;
    @extend %Horizontal_Center;
  }
}

.Icon_Btn {
  padding: $spacer * 0.75;

  &[isloading="true"].Large_Btn {
    min-width: $spacer * 5;
    @extend %Horizontal_Center;
  }

  &[isloading="true"].Small_Btn {
    min-width: $spacer * 4;
    @extend %Horizontal_Center;
  }
}
