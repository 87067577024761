@use "styles/variables" as *;

.Toggle_Container {
  display: flex;
  align-items: flex-start;

  label {
    user-select: none;
  }

  .Toggle_Label {
    top: 1px;
    position: relative;
  }
}

.Toggle {
  position: relative;
  top: 5px;
  display: inline-block;
  width: 35px;
  height: 14px;
  background-color: #dfe1e7;
  border-radius: 1000px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.Toggle:after {
  content: " ";
  position: absolute;
  left: -1px;
  top: -3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ebecef;
  transition: all 0.3s ease;
}

.Checkbox:checked + .Toggle {
  background-color: $bg-icon-color;

  &::after {
    left: 16px;
    background-color: $button-primary-color;
  }
}

/* Checkbox vanished */
.Checkbox {
  display: none;
  cursor: pointer;
}
