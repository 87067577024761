@use "styles/variables" as *;

.Clean_Data {
  margin-top: 1rem;
  border-bottom: 2px solid $bg-navbar-color;
}

.Clean_Data_Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 2px solid $bg-navbar-color;
  border-radius: 4px;
  width: 700px;
  margin: 2rem auto;
}

.KeyValue_Container {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
  line-break: auto;
  margin: 0.7rem;
  cursor: default;

  &:not(:last-of-type) {
    border-bottom: 2px solid $bg-navbar-color;
  }
}
