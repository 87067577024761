@use "styles/colors" as *;

.Filter_Collapsible_Content {
  @extend .Bg_Card_Color;

  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding-left: 2rem;
  transition: all 0.1s ease-in-out;
}

.Filter_Collapsible_Content_Open {
  max-height: 100%;
  padding-block: 16px;
  overflow: visible;
  opacity: 1;
}
