@use "styles/variables" as *;

$overview_container_width: 100%;

.Overview_Container {
  width: $overview_container_width;
  padding: 30px;
  min-height: calc(100vh - 12rem - #{$breadcrumbs-bar-height});
}

.Uploaded_File_Statistic_Container {
  width: 70%;
}

.Uploaded_File_Statistic_Header {
  display: flex;
  padding: 6px 10px;
  flex-direction: row;

  & > * {
    flex: 1;
  }
}

.Header_Title_Container {
  margin: 10px;
}

.Active_Partner_Container {
  width: 30%;
}
.Uploaded_File_Statistic_Row {
  display: flex;
  padding: 10px;
  flex-direction: row;
  & > * {
    flex: 1;
  }
}

.Uploaded_File_Statistic_Card {
  margin: 15px 0;
  border-radius: 8px;
  background: #002848;
  box-shadow: 0px 0px 1px rgb(88 181 255 / 25%),
    0px 4px 8px rgb(88 181 255 / 15%);
}

.Statistic_Content {
  padding: 15px;
}

.Active_Partner_Item {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #005eaa;
  padding: 15px;
}

.Empty_Content_Container {
  margin-top: 6rem;
}
