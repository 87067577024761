@use "styles/fonts" as *;
@use "styles/variables" as *;

.Login {
  margin-top: 5rem;
  padding: 6rem;
  border: 3px solid $bg-dropdown-border-color;
  border-radius: $spacer;
  max-width: 550px;
}

.Forgot_Password {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.Login_Textfields {
  width: 345px;
  margin-bottom: 1rem;
}
