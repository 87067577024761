@use "styles/variables" as *;
@use "styles/fonts" as *;
@use "styles/colors" as *;
@use "styles/effects" as *;
@use "styles/utilities" as *;

.File_Upload_Container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  border: 1px solid $bg-dropdown-border-color;
  background-color: $bg-dropdown-color;
  border-radius: 6px;
  cursor: pointer;

  @extend .Hover_Effect_1;

  &:hover {
    background-color: darken($bg-dropdown-color, 0.4);
  }

  & > input[type="file"] {
    display: none;
  }

  & > label {
    outline: 0;
    user-select: none;

    display: inline-block;
    cursor: pointer;
    width: inherit;
    padding: 0.45rem 1rem;
    text-align: left;

    gap: 0.5rem;

    @extend .Font_H8;
    @extend .Font_Subtitle_Color;
    @extend .text_ellipsis;

    & > svg {
      max-width: 20px;
      margin-right: 0.5rem;
    }
  }
}
