.Detail_Sections_Top_Action_Bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;

  img {
    transition: filter ease 0.1s;

    &:hover {
      filter: brightness(0.7);
    }
  }

  &[disabled] {
    img {
      filter: grayscale(1);
    }
  }
}
