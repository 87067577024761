@use "styles/variables" as *;
@use "styles/fonts" as *;
@use "styles/colors" as *;

.Search_Exports_Section {
  flex: 4;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow: auto;
  border-top: 1px solid $button-selected-color;
}

.Report_Summary {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 15px;
  white-space: nowrap;
  color: $font-title-color;
  padding-right: 9rem;
  cursor: pointer;
}

.Report_Summary_Completed {
  &:hover {
    color: $font-subtitle-color;
  }
}

.Report_File_Statistics {
  display: flex;
  flex-direction: row;
  justify-content: space-start;

  @extend .Font_H10;
}

.Report_Title {
  @extend .Font_H6;
}

.Report_File_Size {
  padding-left: 10px;
}

.Search_Exports_List_Item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.Search_Exports_List_Item_Completed {
  &:hover {
    background-color: lighten($bg-dark-base-color, $amount: 2);
  }
}

.Report_Status_Text {
  color: $font-disabled-color;
}

.No_Recent_Exports_Wrapper {
  margin: auto;
}
