@use "styles/colors" as *;

.Form_Footer {
  @extend .Bg_Card_Color;
  box-shadow: inset 0px 1px 0px #003560;
  min-height: 80px;
  width: 100%;

  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  z-index: 3;

  .Footer_Buttons {
    bottom: -24px;
    position: relative;
    right: 32px;
  }
}
