@use "variables" as *;

@mixin font(
  $font-family,
  $font-size,
  $font-weight,
  $line-height,
  $letter-spacing: normal
) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

//* T1
@mixin lato-28-regular {
  @include font(
    $font-family-lato,
    $font-size-3xl,
    $font-weight-regular,
    38.13px,
    0.2px
  );
}

//* T2
@mixin lato-24-bold {
  @include font(
    $font-family-lato,
    $font-size-2xl,
    $font-weight-bold,
    28px,
    0.2px
  );
}

//* T3
@mixin lato-24-regular {
  @include font(
    $font-family-lato,
    $font-size-2xl,
    $font-weight-regular,
    28px,
    0.2px
  );
}

//* T4
@mixin lato-21-bold {
  @include font(
    $font-family-lato,
    $font-size-xl,
    $font-weight-bold,
    28px,
    0.2px
  );
}

//* T5
@mixin lato-18-bold {
  @include font(
    $font-family-lato,
    $font-size-lg,
    $font-weight-bold,
    22px,
    0.2px
  );
}

//* T6
@mixin lato-18-regular {
  @include font(
    $font-family-lato,
    $font-size-lg,
    $font-weight-regular,
    22px,
    0.2px
  );
}

//* T7
@mixin karla-16-bold {
  @include font(
    $font-family-karla,
    $font-size-base,
    $font-weight-bold,
    22px,
    0.2px
  );
}

//* T8
@mixin karla-16-regular {
  @include font(
    $font-family-karla,
    $font-size-base,
    $font-weight-regular,
    22px,
    0.2px
  );
}

//* T9
@mixin karla-14-bold {
  @include font(
    $font-family-karla,
    $font-size-sm,
    $font-weight-bold,
    20px,
    0.2px
  );
}

//* T10
@mixin karla-14-regular {
  @include font(
    $font-family-karla,
    $font-size-sm,
    $font-weight-regular,
    20px,
    0.2px
  );
}

//* T11
@mixin karla-13-regular {
  @include font(
    $font-family-karla,
    $font-size-xs,
    $font-weight-regular,
    21px,
    0.2px
  );
}

//* T12
@mixin karla-12-bold {
  @include font(
    $font-family-karla,
    $font-size-2xs,
    $font-weight-bold,
    16px,
    0.2px
  );
}

//* T13
@mixin karla-12-regular {
  @include font(
    $font-family-karla,
    $font-size-2xs,
    $font-weight-regular,
    16px,
    0.2px
  );
}

//* T14
@mixin karla-11-regular {
  @include font(
    $font-family-karla,
    $font-size-3xs,
    $font-weight-regular,
    14.98px,
    0.2px
  );
}

//* T15
@mixin karla-10-bold {
  @include font(
    $font-family-karla,
    $font-size-4xs,
    $font-weight-bold,
    12px,
    0.2px
  );
}

@mixin transition($value...) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -ms-transition: $value;
  -o-transition: $value;
  transition: $value;
}

@mixin border-1-solid($color) {
  border: 1px solid $color;
}
