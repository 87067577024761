@use "styles/colors" as *;
@use "styles/fonts" as *;
@use "styles/variables" as *;
@use "styles/effects" as *;

.Top_Performers_Container {
  flex-basis: auto;
  padding-block: 2rem 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 2100px;

  .Top_Performers_Card {
    width: 80%;
    padding: 2rem 0 2rem 2rem;
    border: 1px solid $bg-icon-color;
    overflow: visible;
    border-radius: 4px;

    @extend .Bg_Card_Color;
    @extend .Hover_Effect_2;
  }
}
