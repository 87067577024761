@use "colors" as *;
@use "fonts" as *;
@use "variables" as *;
@use "mixins" as *;

.Expandable_Pill_Container,
.Pill {
  border-radius: 10px;
  height: fit-content;
  min-height: 25px;
  user-select: none;
  cursor: pointer;
  @extend .Bg_Navbar_Color;
}

.Pill {
  white-space: nowrap;
  padding: 2px $spacer;
  user-select: none;
  display: inline-flex;
  border-radius: 1000px;
  margin-right: 10px;
  align-items: center;
}

.Expandable_Pill_Container {
  margin-block: 6px !important;
  flex-direction: column;

  .Pill {
    margin-right: 0;
    position: relative;
    flex-wrap: nowrap;
  }

  svg {
    transition: all 0.1s ease;
  }

  &.Active {
    height: max-content;

    .Pill {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .Expanded_Items {
      overflow: hidden;
      white-space: break-spaces;
      padding: 0 6px 10px 10px;
      max-width: fit-content;
    }
  }
}
