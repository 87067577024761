@use "styles/variables" as *;
@use "styles/fonts" as *;
@use "styles/colors" as *;

.Form {
  padding: 3rem 0 4rem 5rem;
}

.Form_Row {
  display: flex;
  gap: 4rem;
  align-items: center;
  margin-bottom: 2rem;

  > label {
    width: 200px;
    text-align: left;
    user-select: none;

    @extend .Font_H7;
    @extend .Font_Subtitle_Color;
  }
}
