@use "mixins" as *;


//******************* Font Properties *******************//

%Font_H1 {
  @include lato-28-regular;
}

%Font_H2 {
  @include lato-24-bold;
}

%Font_H3 {
  @include lato-24-regular;
}

%Font_H4 {
  @include lato-21-bold;
}

%Font_H5 {
  @include lato-18-bold;
}

%Font_H6 {
  @include lato-18-regular;
}

%Font_H7 {
  @include karla-16-bold;
}

%Font_H8 {
  @include karla-16-regular;
}

%Font_H9 {
  @include karla-14-bold;
}

%Font_H10 {
  @include karla-14-regular;
}

%Font_H11 {
  @include karla-13-regular;
}

%Font_H12 {
  @include karla-12-bold;
}

%Font_H13 {
  @include karla-12-regular;
}

%Font_H14 {
  @include karla-11-regular;
}

%Font_H15 {
  @include karla-10-bold;
}

//******************* Font Classes *******************//

.Font_H1 {
  @extend %Font_H1;
}

.Font_H2 {
  @extend %Font_H2;
}

.Font_H3 {
  @extend %Font_H3;
}

.Font_H4 {
  @extend %Font_H4;
}

.Font_H5 {
  @extend %Font_H5;
}

.Font_H6 {
  @extend %Font_H6;
}

.Font_H7 {
  @extend %Font_H7;
}

.Font_H8 {
  @extend %Font_H8;
}

.Font_H9 {
  @extend %Font_H9;
}

.Font_H10 {
  @extend %Font_H10;
}

.Font_H11 {
  @extend %Font_H11;
}

.Font_H12 {
  @extend %Font_H12;
}

.Font_H13 {
  @extend %Font_H13;
}

.Font_H14 {
  @extend %Font_H14;
}

.Font_H15 {
  @extend %Font_H15;
}
