@use "styles/_colors" as *;
@use "styles/_fonts" as *;

.Group_By_Wrapper {
  margin: 30px 10px;
}

.Group_By_Title {
  margin: 10px 32px 20px 32px;
  display: block;
  text-align: left;

  @extend .Font_H4;
  @extend .Font_Subtitle_Color;
}

.Group_By_Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 35px 0px;
  grid-auto-flow: row;
  width: 1100px;
  margin-left: 70px;
}
