@use "styles/fonts" as *;
@use "styles/variables" as *;

.ForgotPassword {
  margin-top: 5rem;
  padding: 2rem;
  border: 3px solid $bg-dropdown-border-color;
  border-radius: $spacer;
}

.Buttons {
  margin-top: $spacer * 4;
  margin-left: 5.5rem;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
