@use "styles/variables" as *;
@use "styles/colors" as *;
@use "styles/fonts" as *;

.Collapsible_Menu_Header {
  display: flex;
  align-content: center;
  justify-content: space-between;
  border: 2px solid $bg-navbar-color;
  border-radius: 10px;
  margin: 0.5rem auto;
  padding: 0.5rem;
  transition: all 0.3s ease;
  user-select: none;

  svg {
    transition: all 0.3s ease;
  }

  @extend .Font_H8;
  @extend .Font_Title_Color;
  @extend .Bg_Card_Color;

  &:hover {
    background-color: lighten($bg-card-color, 5);
  }
}

.Disabled_Header {
  cursor: not-allowed;

  @extend .Font_Disabled_Color;

  &:hover {
    background-color: $bg-card-color;
  }

  svg {
    opacity: 50%;
  }
}
