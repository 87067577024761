@use "styles/variables" as *;

.Pivot_Container_Search_Detail {
  height: calc(
    100vh - 11.9rem - #{$dashboard-tabs-bar-height} - #{$search-detail-top-bar-height}
  );
}

.Search_Detail_Custom_loader {
  height: calc(96vh - 11.9rem - #{$dashboard-tabs-bar-height});
  display: flex;
}
