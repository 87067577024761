@use "styles/effects" as *;
@use "styles/colors" as *;
@use "styles/variables" as *;
@use "styles/fonts" as *;

.Links_Wrapper {
  display: flex;
  flex-direction: column;
  margin-top: $spacer * 4;
}

.Links_Container {
  width: 350px;
  min-height: 250px;
  border-radius: 8px;
  padding: 24px;
  margin: 36px;

  @extend .Bg_Card_Color;
  @extend .Hover_Effect_2;
}

.Links {
  display: flex;
  justify-content: space-between;
  padding: $spacer 0;
  border-bottom: 1px solid $bg-icon-color;
  cursor: pointer;

  a {
    @extend .Font_H7;
    @extend .Font_Link_Color;

    &:focus,
    &:hover {
      @extend .Info_Font_Color;
    }
  }

  svg {
    transition: all 0.5s ease;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
}

.Links_Title {
  span:after {
    border: 0px;
  }
}
