@use "styles/fonts" as *;
@use "styles/variables" as *;
@use "styles/colors" as *;
@use "styles/mixins" as *;

.Radio_Button_Container {
  display: flex;
  gap: $spacer;
  align-items: center;
  justify-content: center;

  @extend .Font_H10;
  @extend .Font_Title_Color;

  label {
    cursor: pointer;
    position: relative;

    // ! Adjust this width to adjust gap between radio and label
    // ! Without fixed width, the content width will get change if we make label bold
    min-width: 165px;
    padding-left: 30px;
    text-align: start;  

    @include transition(all, $button-hover-duration);
  }

  // * Make default radio button invisible
  input[type="radio"] {
    appearance: none;
  }

  // * Outside circle
  input[type="radio"] + label::before {
    content: "";
    display: inline-block;
    width: $spacer * 2;
    aspect-ratio: 1;
    border: 1px solid $bg-icon-color;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 1;
    z-index: 3;

    @include transition(all, $button-hover-duration);
  }

  // * Inner circle
  input[type="radio"] + label::after {
    content: "";
    display: inline-block;
    width: 6px;
    aspect-ratio: 1;
    border: 1px solid #57b4fa;
    background: #57b4fa;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
  }

  // * Selected radio button
  input[type="radio"]:checked {
    & + label {
      @extend .Font_H9;

      &::before {
        border: 1px solid #57b4fa;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  // * Hover circle around radio button
  input[type="radio"]:not(:checked):not(:disabled) {
    & + label:hover {
      &::after {
        height: 33px;
        width: 33px;
        background-color: $bg-navbar-color;
        z-index: 1;
        border: 1px solid transparent;
        opacity: 1; 
        top: 50%;
        left: 7.5px;
        transform: translate(-50%, -50%);
      }

      &::before {
        border-color: #ffffff;
      }
    }
  }

  // * Disabled radio button
  input[type="radio"]:disabled {
    & + label {
      cursor: not-allowed;
    }
    & + label::after {
      background: #004780;
      border-color: #004780;
    }
    & + label::before {
      border-color: #004780;
    }
  }
}
