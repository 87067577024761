@use "styles/variables" as *;

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.table-layout-fixed {
  table-layout: fixed;
}

.centered-div {
  margin: 0 auto;
}

.break-long-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  text-align: start;
}

.no-pointer-event {
  pointer-events: none;
}

// * Text Ellipsis Styles
%text_ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
}

.text_ellipsis {
  @extend %text_ellipsis;
}

.transform-origin-center {
  transform-origin: center;
}

.transform-box-fill-box {
  transform-box: fill-box;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.border-bottom-1 {
  border-bottom: 1px solid $bg-icon-color;
}

.border-color-light-blue {
  border-color: #005eaa !important;
}

.rotate-n90 {
  transform: rotate(-90deg);
}
