@use "styles/utilities" as *;
@use "styles/colors" as *;
@use "styles/variables" as *;

.Card_Container {
  min-height: auto;
  width: inherit;
  margin: 36px 0;

  .Card_Header {
    padding-right: 5px;
    transition: all 0.3s ease;
  }

  &:hover {
    .Card_Header {
      padding-right: 0px;
    }
  }
}

.Link_Wrapper {
  span {
    max-width: 200px;
    @extend .text_ellipsis;
  }
}
