@use "styles/variables" as *;

.LoanId_Container {
  min-width: 280px;
  padding: 1rem;
}

.LoanId_Field {
  padding: 1.5rem;

  > input {
    border: 1px solid $bg-icon-color;
  }
}
