@use "styles/variables" as *;
@use "styles/fonts" as *;

.Detail_Container {
  border-bottom: 2px solid $bg-navbar-color;
}

.PA_Detail {
  margin: 0.7rem;
}

.PA_Detail_Heading {
  display: flex;
  justify-content: space-between;
  margin: 1.2rem;

  @extend .Font_H5;
}
