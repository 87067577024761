@use "variables" as *;

// ********* Button classes ********* //

%Button_Primary_Color {
  background-color: $button-primary-color;
}

%Button_Hover_Color {
  background-color: $button-hover-color;
}

%Button_Selected_Color {
  background-color: $button-selected-color;
}

%Button_Danger_Color {
  background-color: $button-danger-color;
}

.Button_Primary_Color {
  @extend %Button_Primary_Color;
}

.Button_Hover_Color {
  @extend %Button_Hover_Color;
}

.Button_Selected_Color {
  @extend %Button_Selected_Color;
}

.Button_Danger_Color {
  @extend %Button_Danger_Color;
}

// ********* Background classes ********* //

%Bg_Tooltip_Color {
  background-color: $bg-tooltip-color;
}

%Bg_Modal_Color {
  background-color: $bg-modal-color;
}

%Bg_Icon_Color {
  background-color: $bg-icon-color;
}

%Bg_Navbar_Color {
  background-color: $bg-navbar-color;
}

%Bg_Light_Base_Color {
  background-color: $bg-light-base-color;
}

%Bg_Dark_Base_Color {
  background-color: $bg-dark-base-color;
}

%Bg_Card_Color {
  background-color: $bg-card-color;
}

%Bg_Test_Server_Color {
  background-color: lighten($bg-card-color, 15);
}

.Bg_Tooltip_Color {
  @extend %Bg_Tooltip_Color;
}

.Bg_Modal_Color {
  @extend %Bg_Modal_Color;
}

.Bg_Icon_Color {
  @extend %Bg_Icon_Color;
}

.Bg_Navbar_Color {
  @extend %Bg_Navbar_Color;
}

.Bg_Light_Base_Color {
  @extend %Bg_Light_Base_Color;
}

.Bg_Dark_Base_Color {
  @extend %Bg_Dark_Base_Color;
}

.Bg_Card_Color {
  @extend %Bg_Card_Color;
}

.Bg_Test_Server_Color {
  @extend %Bg_Test_Server_Color;
}

// ********* Font Color classes ********* //

%Font_Title_Color {
  color: $font-title-color;
}

%Font_Subtitle_Color {
  color: $font-subtitle-color;
}

%Font_Body_Color {
  color: $font-body-color;
}

%Font_Disabled_Color {
  color: $font-disabled-color;
}

%Font_Button_Color {
  color: $font-button-color;
}

%Font_Link_Color {
  color: $font-link-color;
}

.Font_Title_Color {
  @extend %Font_Title_Color;
}

.Font_Subtitle_Color {
  @extend %Font_Subtitle_Color;
}

.Font_Body_Color {
  @extend %Font_Body_Color;
}

.Font_Disabled_Color {
  @extend %Font_Disabled_Color;
}

.Font_Button_Color {
  @extend %Font_Button_Color;
}

.Font_Link_Color {
  @extend %Font_Link_Color;
}

// ********* Semantic Background classes ********* //

%Success_Bg_Color {
  background-color: $success-bg-color;
}

%Info_Bg_Color {
  background-color: $info-bg-color;
}

%Alert_Bg_Color {
  background-color: $alert-bg-color;
}

%Error_Bg_Color {
  background-color: $error-bg-color;
}

.Success_Bg_Color {
  @extend %Success_Bg_Color;
}

.Info_Bg_Color {
  @extend %Info_Bg_Color;
}

.Alert_Bg_Color {
  @extend %Alert_Bg_Color;
}

.Error_Bg_Color {
  @extend %Error_Bg_Color;
}

// ********* Semantic Font classes ********* //

%Success_Font_Color {
  color: $success-font-color;
}

%Info_Font_Color {
  color: $info-font-color;
}

%Alert_Font_Color {
  color: $alert-font-color;
}

%Error_Font_Color {
  color: $error-font-color;
}

.Success_Font_Color {
  @extend %Success_Font_Color;
}

.Info_Font_Color {
  @extend %Info_Font_Color;
}

.Alert_Font_Color {
  @extend %Alert_Font_Color;
}

.Error_Font_Color {
  @extend %Error_Font_Color;
}

// ********* Semantic Stroke classes ********* //

%Success_Stroke_Color {
  border: 1px solid $success-stroke-color;
}

%Info_Stroke_Color {
  border: 1px solid $info-stroke-color;
}

%Alert_Stroke_Color {
  border: 1px solid $alert-stroke-color;
}

%Error_Stroke_Color {
  border: 1px solid $error-stroke-color;
}

.Success_Stroke_Color {
  @extend %Success_Stroke_Color;
}

.Info_Stroke_Color {
  @extend %Info_Stroke_Color;
}

.Alert_Stroke_Color {
  @extend %Alert_Stroke_Color;
}

.Error_Stroke_Color {
  @extend %Error_Stroke_Color;
}
