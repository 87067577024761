@use "styles/fonts" as *;
@use "styles/mixins" as *;
@use "styles/colors" as *;

.Result_Summary_Wrapper {
  padding: 36px 30px 36px 50px;
  flex: auto;
  max-width: none;
  height: fit-content;
  overflow: auto;

  span {
    text-align: left;
    white-space: nowrap;
  }
}

.First_Level_Summary_Wrapper {
  padding: 12px 0 12px 1rem;

  .First_Level_Title {
    display: flex;
    justify-content: flex-start;

    @extend .Font_H4;
    @extend .Font_Title_Color;
  }
}

.Second_Level_Summary_Wrapper {
  padding: 12px 0 12px 5rem;

  &:first-of-type {
    padding-top: 3rem;
  }

  .Second_Level_Title {
    display: flex;
    justify-content: flex-start;

    @extend .Font_H6;
    @extend .Font_Title_Color;
  }
}

.Third_Level_Summary_Wrapper {
  padding: 12px 0 12px 6rem;
  .Third_Level_Title {
    display: flex;
    padding: 12px 0 12px 23rem;
    @extend .Font_H9;
    @extend .Font_Title_Color;

    & > span:first-of-type {
      padding-right: 5rem;
    }
  }
}

.Fourth_Level_Summary_Wrapper {
  padding: 12px 0 12px 4rem;
  display: flex;

  & > span:first-of-type {
    padding-right: 9rem;
  }

  .Fourth_Level_Title {
    display: flex;
    justify-content: flex-start;
    width: 250px;

    margin-right: 4rem;

    @extend .Font_H8;
    @extend .Font_Subtitle_Color;
  }
}

.Detailed_Stats {
  @extend .Font_H10;
  @extend .Font_Title_Color;

  width: 20px;
  overflow: visible;
  display: inline-block;
}

.No_Result_Summary_Found {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
