@use "variables" as *;
@use "fonts" as *;

.Toast_Wrapper {
  border-radius: 4px;
  padding: $spacer $spacer * 2;
  margin-block: $spacer * 2;
}

.Toast_Body {
  margin: 0;
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
  white-space: pre-line;
}
