@use "styles/colors" as *;
@use "styles/fonts" as *;

.Tooltip {
  max-width: 200px;
  margin: 0;
  line-height: 24px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px #58b5ff26;

  @extend .Bg_Icon_Color;

  .Tooltip_Title {
    border-bottom: 1px solid #f5f5f53d;
    padding-bottom: 6px;
    margin-bottom: 6px;

    @extend .Font_H10;
  }

  .Tooltip_Desc {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
}
