@use "styles/effects" as *;
@use "styles/colors" as *;

.Scroll_To_Top_Btn {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 2;

  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  outline: none;

  @extend .Info_Stroke_Color;
  @extend .Bg_Card_Color;
  @extend .Hover_Effect_3;

  &:active {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
}

