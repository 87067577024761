@use "styles/fonts" as *;
@use "styles/mixins" as *;
@use "styles/colors" as *;

.File_Error_Wrapper {
  padding: 26px 20px 36px 30px;
  min-height: auto;
  max-width: none;
  min-width: 600px;
  height: fit-content;
  overflow: auto;
  flex: auto;

  span {
    text-align: left;
    white-space: nowrap;
  }

  span[role="label"] {
    width: 25rem;
    text-align: right;
  }

  span[role="error"],
  span[role="error-obj"] {
    text-align: left;
    padding-bottom: 6px;
    width: 25rem;
    white-space: pre-wrap;
  }

  span[role="error-obj"] {
    display: flex;
    gap: 12px;

    * {
      white-space: pre-wrap;
    }

    :first-child {
      white-space: nowrap;
    }
  }

  .File_Problem_Error_Container {
    padding: 24px 5rem 12px;
  }

  .Error_Container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .Error_Msg_Flex_Container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .Sheet_Error_Container {
    padding: 24px 0rem 12px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #005eaa;
    }

    header {
      padding-bottom: 24px;
    }
  }
}
