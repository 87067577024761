@use "styles/variables" as *;
@use "styles/mixins" as *;
@use "styles/colors" as *;
@use "styles/fonts" as *;

.Dashboard_Tabs {
  user-select: none;

  @extend .Font_H8;
  @extend .Font_Subtitle_Color;
}

.Sticky_Dashboard_Tab {
  position: relative;
  height: 100%;
}

.Dashboard_Tab_List {
  width: 100%;
  border: none;
  border-bottom: 2px solid $bg-navbar-color;
  padding: $spacer 0 0 $spacer * 2;
  margin-bottom: 0;
  margin-right: $spacer * 2;
  text-align: start;

  li {
    border: 2px solid transparent;
    padding: 12px $spacer * 2;
    margin-right: $spacer * 2;
    border-radius: $spacer $spacer 0 0;

    @extend .Bg_Card_Color;
    @extend .Font_Subtitle_Color;
  }

  li:focus {
    border: 2px solid $button-primary-color;
    border-bottom: none;
    border-radius: $spacer $spacer 0 0;
    box-shadow: none;

    @extend .Font_Title_Color;
  }

  li:focus::after {
    content: none;
  }
}

.Sticky_Tab_List {
  position: sticky;
  top: 0;
  z-index: 1;
}

.Dashboard_Selected_Tab {
  color: $font-title-color !important;
  border: 2px solid $button-primary-color !important;
  border-bottom: none !important;

  &:focus::after {
    content: none;
  }
}
