@use "variables" as *;
@use "colors" as *;
@use "fonts" as *;
@use "forms" as *;
@use "effects" as *;
@use "toasts" as *;
@use "tables" as *;
@use "scrollbar" as *;
@use "pills" as *;
@use "filter_item" as *;
@use "recharts" as *;
@use "datepicker" as *;
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Lato:wght@400;700&display=swap");

//******* Reset CSS styles ********//

button,
fieldset,
iframe,
img {
  border: 0;
}

li {
  list-style: none;
}

//******* Global styles ********//

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;

  @extend %Bg_Dark_Base_Color;
  @extend %Font_Body_Color;
  @extend %Font_H7;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px $bg-dropdown-color inset !important;
  -webkit-text-fill-color: $font-body-color !important;
  border: 1px solid $bg-dropdown-color !important;
}
