@use "styles/fonts" as *;
@use "styles/variables" as *;

.Reset_Password_Container {
  margin-top: 5rem;
  padding: 2rem;
  border: 3px solid $bg-dropdown-border-color;
  border-radius: $spacer;

  & > svg {
    max-width: 300px;
  }

  .Button {
    display: flex;
    justify-content: space-around;
  }
}
