@use "styles/variables" as *;
@use "styles/fonts" as *;
@use "styles/colors" as *;
@use "styles/mixins" as *;

// * Defined Range - Left Side section
.rdrDefinedRangesWrapper {
  border-radius: 6px 0% 0% 6px / 6px 0% 0% 6px;
  background: $bg-card-color;
  padding-top: 20px;
  width: 200px;
  border: none;

  .rdrStaticRanges {
    .rdrStaticRange {
      background: $bg-card-color;
      color: $font-body-color;
      border-bottom: none;

      @extend .Font_H10;

      .rdrStaticRangeLabel {
        position: relative;
        padding: 10px 20px 10px 55px;
      }

      // * Outside circle
      .rdrStaticRangeLabel::before {
        content: "";
        display: inline-block;
        width: $spacer * 2;
        aspect-ratio: 1;
        border: 1px solid $bg-icon-color;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 25px;
        transform: translateY(-50%);
        opacity: 1;
        z-index: 3;

        @include transition(all, $button-hover-duration);
      }

      // * Inner circle
      .rdrStaticRangeLabel::after {
        content: "";
        display: inline-block;
        width: 6px;
        aspect-ratio: 1;
        border: 1px solid #57b4fa;
        background: #57b4fa;
        border-radius: 50%;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
      }
    }

    // * Selected radio button
    .rdrStaticRange.rdrStaticRangeSelected {
      color: $font-title-color !important;

      @extend .Font_H9;

      .rdrStaticRangeLabel {
        @extend .Font_H9;

        &::before {
          border: 1px solid #57b4fa;
        }

        &::after {
          opacity: 1;
        }
      }
    }

    // * Radio button hover
    .rdrStaticRange:not(.rdrStaticRangeSelected) {
      .rdrStaticRangeLabel:hover {
        &::after {
          height: 33px;
          width: 33px;
          background-color: $bg-navbar-color;
          z-index: 1;
          border: 1px solid transparent;
          opacity: 1;
          top: 50%;
          left: 32px;
          transform: translate(-50%, -50%);
        }

        &::before {
          border-color: #ffffff;
        }
      }
    }

    .rdrStaticRange:hover {
      .rdrStaticRangeLabel {
        background: $bg-card-color;
      }
    }
  }
}

// * Calendar - Right Side section
.rdrCalendarWrapper {
  border-radius: 0% 6px 6px 0% / 0% 6px 6px 0%;
  background: $bg-card-color;

  // * Month and Year pickers
  .rdrMonthAndYearWrapper {
    .rdrMonthAndYearPickers {
      .rdrMonthPicker,
      .rdrYearPicker {
        border-radius: 6px;
        select {
          background: url("../images/nav-dropdown-arrow.svg") no-repeat;
          @extend .Font_Title_Color;
          @extend .Font_H9;

          // ! Apply minimal styles to select option
          option {
            background: $bg-card-color;
            color: $font-body-color;
          }
        }
      }

      .rdrMonthPicker {
        select {
          background-position: left 90px center;
        }
      }

      .rdrYearPicker {
        select {
          background-position: left 70px center;
        }
      }
    }
  }

  // * Calendar months
  .rdrMonths {
    .rdrMonth {
      // * Weekdays
      .rdrWeekDays {
        .rdrWeekDay {
          @extend .Font_Subtitle_Color;
          @extend .Font_H15;
        }
      }

      // * Days
      .rdrDays {
        @extend .Font_H10;

        .rdrDay {
          .rdrDayNumber span {
            color: $font-title-color;
            z-index: 1;
          }

          // * Today's date
          &.rdrDayToday {
            .rdrDayNumber span {
              color: #58b5ff !important;

              &::after {
                background: #58b5ff;
              }
            }
          }

          // * Disabled dates
          &.rdrDayDisabled {
            background: none;
            cursor: not-allowed;

            .rdrDayNumber span {
              color: $font-disabled-color !important;
            }
          }
        }

        // * Selected Range trail
        .rdrStartEdge,
        .rdrEndEdge,
        .rdrInRange {
          border-radius: 0;
          background: #004379;
        }

        // * Preview trail
        .rdrDayStartPreview,
        .rdrDayEndPreview,
        .rdrDayInPreview {
          border-radius: 0;
          border: none;
          background: #002848;
        }

        // * On day hover
        .rdrDay.rdrDayHovered {
          .rdrDayNumber:after {
            content: "";
            border: none;
            border-radius: 0;
            position: absolute;
            top: -2px;
            bottom: -2px;
            left: 0px;
            right: 0px;
            background: #002848;
            z-index: 0;
          }
        }

        .rdrDayPassive {
          .rdrDayNumber span {
            color: #004379 !important;
          }
        }
      }
    }
  }
}

// * Month and Year Arrows

.rdrPrevButtonClassName,
.rdrNextButtonClassName {
  i {
    border: none;
  }
}

.rdrPrevButtonClassName {
  background: url("../images/ic_date_prev_arrow.svg") no-repeat center;

  &:hover,
  &:focus {
    background: url("../images/ic_date_prev_arrow.svg") no-repeat center,
      $bg-navbar-color;
  }
}

.rdrNextButtonClassName {
  background: url("../images/ic_date_next_arrow.svg") no-repeat center;

  &:hover,
  &:focus {
    background: url("../images/ic_date_next_arrow.svg") no-repeat center,
      $bg-navbar-color;
  }
}
