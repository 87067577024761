@use "variables" as *;

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5px #ffffff12;
  -webkit-box-shadow: inset 0 0 0.5px #ffffff12;
  background: #ffffff1f;
  padding-block: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ffffff82;
  box-shadow: inset 0 0 3px 0 #00000099;
  background-clip: padding-box;
  border: 4px solid #00000000;
}
