@use "styles/variables" as *;

.Sorting_Icon {
  position: relative;
  left: clamp(60%, 10%, 5%);
  width: 20px;
  height: 20px;

  &::before,
  &::after {
    color: $icon-primary-color;
    position: absolute;
    right: 0;
  }

  &::before {
    content: "▼";
    top: 5px;
    opacity: 0.4;
  }

  &::after {
    content: "▲";
    top: -5px;
    opacity: 0.4;
  }
}

.Sorting_Icon--asc {
  &::after {
    content: "▲";
    opacity: 1;
  }
}

.Sorting_Icon--desc {
  &::before {
    content: "▼";
    opacity: 1;
  }
}
