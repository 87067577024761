@use "styles/colors" as *;
@use "styles/variables" as *;
@use "styles/fonts" as *;
@use "styles/effects" as *;

@mixin placeholder-styles {
  color: $font-body-color;
  font-family: $font-family-karla;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  line-height: 22px;
}

.Date_Range_Picker_Input_Wrapper {
  position: relative;
  width: fit-content;
  display: inline-block;
}

.Date_Range_Picker_Input {
  height: 44px;
  width: 330px;
  border-radius: 6px;
  background: url("../../../images/ic_calendar.svg") no-repeat 290px,
    $bg-card-color;
  padding: 9px 15px;
  border: 1px solid #002848;
  cursor: default;

  &::-webkit-input-placeholder {
    @include placeholder-styles;
  }

  &::-moz-placeholder {
    @include placeholder-styles;
    opacity: 1;
  }

  &::-ms-placeholder {
    @include placeholder-styles;
  }

  &::placeholder {
    @include placeholder-styles;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.Date_Range_Picker_Wrapper {
  position: absolute;
  top: 42px;
  left: 0;
  border-radius: 6px !important;
  z-index: 4;
  @extend .Hover_Effect_3;
}

.Date_Range_Cancelable_Pill_Wrapper {
  position: absolute;
  left: 13px;
  top: 8px;
}
