@use "styles/colors" as *;
@use "styles/effects" as *;
@use "styles/variables" as *;

.Overview_Container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  > * {
    flex: 1;
  }
}

.Find_Record {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 2100px;
  padding-block: 1rem;
  margin-inline: auto;
  margin-bottom: 2rem;
  border-radius: 8px;
  border: 1px solid $bg-icon-color;

  @extend .Bg_Card_Color;
  @extend .Hover_Effect_2;
}
