.Cancelable_Pill_Wrapper {
  gap: 10px;
  align-items: center;
  cursor: default;

  .Cancelable_Pill_Close_Icon {
    width: 12px;
    aspect-ratio: 1;
    position: relative;
    cursor: pointer;

    img {
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
