.Assign_PA_Modal_Content_Container {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 14px 40px;

  .Assign_PA_Modal_Wizard_Wrapper {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .Assign_PA_Modal_Actions {
    flex-shrink: 0;
  }
}

.ProjectActivity_Dropdown {
  top: 20px;
}

// * #####################################
// * Animations
// * #####################################

.Animated {
  animation-duration: 0.1s;
}

@keyframes intro {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Intro {
  animation: intro 0.5s ease;
}
