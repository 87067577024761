@use "styles/colors" as *;
@use "styles/fonts" as *;
@use "styles/variables" as *;
@use "styles/mixins" as *;

.Nav_Menu_Title {
  > a {
    padding: $spacer $spacer * 2;
    color: $font-title-color !important;
    @extend .Font_H8;

    &::after {
      content: url("/images/nav-dropdown-arrow.svg");
      border: none;
      margin-left: $spacer;
      vertical-align: text-bottom;
    }
  }
}

.Nav_Menu_Title {
  user-select: none;

  > a {
    padding: $spacer $spacer * 2;
    color: $font-title-color !important;
  }

  > div {
    border: 1px solid $button-selected-color;
    @extend .Bg_Card_Color;
  }
}

.Nav_Menu_Item {
  @extend .Font_Title_Color;

  &:hover,
  &:active,
  &:focus {
    color: $button-primary-color;
    background-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 1px $button-primary-color;
  }
}

.Nav_Menu_Role {
  cursor: not-allowed;
  color: $font-disabled-color;
  border-bottom: 1px solid $bg-icon-color;
  padding-bottom: $spacer;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    color: $font-disabled-color;
    border-bottom: 1px solid $bg-icon-color;
  }
}
