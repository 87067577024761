@use "styles/variables" as *;
@use "styles/fonts" as *;
@use "styles/colors" as *;

.Archive_Excluded_Raw_Data {
  margin-top: 1rem;
  border-bottom: 2px solid $bg-navbar-color;
}

.Excluded_Data_Reason {
  margin: 1rem auto;
  border: 2px solid $bg-navbar-color;
  border-radius: 4px;
  width: 700px;
}

.Excluded_Data_Reason_Heading {
  padding: 0.7rem;
  background-color: $info-bg-color;

  @extend .Font_H7;
  @extend .Font_Title_Color;
}

.File_Info_Container {
  section {
    width: 700px;
    margin: auto;
  }
}
