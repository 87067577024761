@use "styles/variables" as *;
@use "styles/fonts" as *;
@use "styles/colors" as *;
@use "styles/effects" as *;

// * Input styles
.react-datepicker-wrapper {
  width: 20% !important;

  .react-datepicker__input-container {
    input {
      color: $font-body-color;
      background: url("../images/ic_calendar.svg") no-repeat 95%, $bg-card-color;
      border: 1px solid $bg-dropdown-border-color;
      width: 100%;
      padding: 0.5rem 1rem;
      outline: none;
      border-radius: 6px;

      &:disabled {
        cursor: not-allowed;
        background-color: $bg-dark-base-color;
        color: $font-disabled-color;
      }
    }

    .react-datepicker__close-icon {
      right: 40px;

      &::after {
        background-color: transparent !important;
        content: "";
        background: url("../images/ic_close.svg") no-repeat;
      }
    }
  }
}

// * Popper styles
.react-datepicker {
  @extend .Hover_Effect_1;

  border: none !important;

  // * Popper arrow styles
  .react-datepicker__triangle {
    &::before,
    &::after {
      border-bottom-color: $bg-card-color !important;
      border-top-color: $bg-card-color !important;
    }
  }
}

// ***************************************
// *        Top header section
// ***************************************

// * Top header and bottom calendar section
.react-datepicker__header,
.react-datepicker {
  background-color: $bg-card-color !important;
}

.react-datepicker__header {
  border-bottom: 1px solid transparent !important;
}

// * Current month title
.react-datepicker__current-month {
  @extend .Font_H9;

  color: $font-title-color !important;
  padding-bottom: 10px;
}

// ***************************************
// *        Top header dropdowns
// ***************************************

.react-datepicker__header__dropdown {
  padding-bottom: 10px;

  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    margin: 0 20px !important;
  }

  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year {
    color: $font-subtitle-color !important;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    top: 6px !important;
  }

  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    background-color: #004379 !important;
    border: none !important;
    color: $font-subtitle-color !important;
  }

  .react-datepicker__month-option:hover,
  .react-datepicker__year-option:hover {
    background-color: darken(#004379, 10) !important;
  }

  .react-datepicker__month-option--selected_month,
  .react-datepicker__year-option--selected_year {
    background-color: $bg-card-color !important;
  }

  .react-datepicker__navigation--years-upcoming,
  .react-datepicker__navigation--years-previous {
    &::before {
      border-color: #ccc;
      border-style: solid;
      border-width: 3px 3px 0 0;
      content: "";
      display: block;
      height: 9px;
      position: absolute;
      top: 16px;
      width: 9px;
      right: 10px;
    }
  }

  .react-datepicker__navigation--years-upcoming::before {
    transform: rotate(313deg);
    top: 16px;
  }

  .react-datepicker__navigation--years-previous::before {
    transform: rotate(137deg);
    top: 10px;
  }
}

// ***************************************
// *        Calendar section
// ***************************************

// * Day names (Sun, Mon etc.)
.react-datepicker__day-name {
  @extend .Font_H15;

  margin: 5px !important;
  line-height: 12px !important;
  color: $font-title-color !important;
}

// * Each day inside picker
.react-datepicker__day {
  @extend .Font_H10;

  margin: 5px !important;
  color: $font-subtitle-color !important;
}

.react-datepicker__day:hover {
  border-radius: 3px !important;
  background-color: $bg-navbar-color !important;
}

// * Selected day
.react-datepicker__day--selected {
  border-radius: 3px !important;
  background-color: #004379 !important;
  color: $font-title-color !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

// * Today date
.react-datepicker__day--today:not(.react-datepicker__day--selected) {
  color: #58b5ff !important;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 100%;
    border-bottom: solid 1px #58b5ff;
  }
}

// * Disabled Dates
.react-datepicker__day--disabled {
  cursor: not-allowed !important;
  color: $font-disabled-color !important;

  &:hover {
    background-color: transparent !important;
  }
}

// ***************************************
// *        Time input section
// ***************************************

.react-datepicker__time-container {
  border-left: none !important;

  .react-datepicker-time__header {
    color: $font-subtitle-color !important;
  }

  .react-datepicker__time {
    background: $bg-card-color !important;
  }

  .react-datepicker__time-list-item {
    @extend .Font_H12;

    color: $font-body-color !important;

    &:hover {
      background-color: darken(#004379, 10) !important;
    }
  }
}

input.react-datepicker-time__input {
  border: 1px solid $bg-card-color;
  border-radius: 3px;
  padding: 3px 9px;
  color: $font-subtitle-color !important;
  background-color: #004379;
  outline: none;
}
