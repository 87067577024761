.Sales_Chart_Filter {
  @media only screen and (max-width: 1859px) {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
}

.Product_Type_Container {
  height: 44px !important;
}
