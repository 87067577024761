@use "styles/fonts" as *;
@use "styles/colors" as *;

.Filter_Icon_Wrapper {
  cursor: pointer;
  padding-left: 1rem;
  position: relative;

  svg:hover {
    filter: opacity(0.9);
  }

  .Filter_Icon_Bubble {
    position: absolute;
    top: -8px;
    right: -8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    vertical-align: middle;
    padding-top: 2px;
    padding-left: 1px;
    z-index: 3;
    user-select: none;

    @extend .Font_H15;
    @extend .Bg_Icon_Color;
    @extend .Font_Title_Color;
    @extend .Info_Stroke_Color;
  }
}
