@use "styles/variables" as *;

.Search_Result_Container {
  height: calc(100vh - 3.8rem - #{$breadcrumbs-bar-height});
  display: flex;
}

.Right_Section_Container {
  flex: 7.3;
  display: flex;
  justify-content: right;
  flex-direction: column;
  border-top: 2px solid $button-selected-color;
  overflow-x: auto;
  overflow-y: hidden;
}
