@use "styles/colors" as *;

.Count_Table_Card_Container {
  width: clamp(400px, 450px, 40%);
  min-height: 300px;
  border-radius: 8px;
  border: 1px solid #003560;
  padding: 24px;
  margin: 36px;

  @extend .Bg_Card_Color;
}

.Count_Table_Item_Wrapper {
  display: flex;
  justify-content: space-between;
  padding: 9px 5px 16px 5px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #005eaa;
  }
}

.Count_Table_Illustration_Wrapper {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
