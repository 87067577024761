@use "styles/variables" as *;

.Project_Activity_List {
  width: 100%;
  top: 0.9rem;
  color: $info-stroke-color;
}

.List_Scroll_Table {
  overflow-x: auto;
  height: calc(100vh - 8rem);
}
