@use "styles/variables" as *;

.Records_Table {
  overflow: auto;
  height: calc(
    100vh - 11.9rem - #{$dashboard-tabs-bar-height} - #{$search-detail-top-bar-height}
  );
}

.Scroll_To_Top_Btn_Search_detail {
  bottom: 85px;
}

.Record_Search_Field {
  width: 350px;
}

.Count_Mismatch_Container {
  height: calc(
    100vh - 16.9rem - #{$dashboard-tabs-bar-height} - #{$search-detail-top-bar-height}
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Search_Detail_Custom_loader {
  height: calc(96vh - 11.9rem - #{$dashboard-tabs-bar-height});
  display: flex;
}
