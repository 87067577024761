.Promotion_Modal_Content_Container {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 14px 40px;

  .Promotion_Modal_Msg {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    user-select: none;
  }

  .Promotion_Modal_Actions {
    flex-shrink: 0;
  }
}
