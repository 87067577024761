@use "styles/variables" as *;
@use "styles/_fonts" as *;
@use "styles/_colors" as *;

.Nested_Dropdown {
  display: inline-block;
}

// * Override default react-multilevel-dropdown styles
.Nested_Dropdown_Wrapper {
  display: block;
  height: 44px;
  width: 332px;
  border: 0px;
  border-radius: 6px;
  border: 1px solid $bg-dropdown-border-color;
}

.Nested_Dropdown_Button {
  width: 100% !important;
  height: 100% !important;
  background-color: $bg-dropdown-color !important;
  cursor: default !important;

  &:focus {
    box-shadow: none !important;
  }
}

.Dropdown_Indicator {
  background-color: $bg-dropdown-color;
  border: 0px;
  border-radius: 6px;
  padding-left: 10px;
  position: absolute;
  right: 17.67px;
}

.Nested_Dropdown_Title_Text {
  text-align: left;
  position: absolute;
  margin: 0px 8px;
  left: 8px;
  top: 9px;

  @extend .Font_Title_Color;
  @extend .Font_H8;
}

.Nested_Dropdown_Placeholder_Text {
  text-align: left;
  position: absolute;
  margin: 0px 8px;
  left: 8px;
  top: 9px;

  @extend .Font_H8;
  @extend .Font_Body_Color;
}

.Nested_Dropdown_Menu {
  cursor: default !important;
  border-radius: 6px !important;
  margin: 4px 0px;
  width: 100%;
  padding: 0px;
  box-shadow: 0px 0px 1px rgba(88, 181, 255, 0.25),
    0px 4px 8px rgba(88, 181, 255, 0.15) !important;
  ul {
    margin: 0px !important;
  }
}

.Nested_Dropdown_Items {
  background-color: $bg-dropdown-color;
  cursor: default !important;

  &:hover {
    background-color: $bg-dropdown-option-hover-color !important;
  }
}

.Item_active {
  background-color: $button-selected-color !important;
}

.Nested_DropDown_Item_Name {
  @extend .Font_Title_Color;
  @extend .Font_H8;
}

.Nested_Dropdown_Submenu {
  cursor: default !important;
  width: 100%;
  margin-left: 7px;
  border-radius: 6px !important;
  box-shadow: 0px 0px 1px rgba(88, 181, 255, 0.25),
    0px 4px 8px rgba(88, 181, 255, 0.15) !important;
}

.Arrow_Icon {
  padding: 0px 15px;
}
