@use "variables" as *;

%Hover_Effect {
  box-shadow: none;
  transition: box-shadow 1s;
}

.Hover_Effect_1 {
  @extend %Hover_Effect;

  &:hover {
    box-shadow: 0px 1px 1px 0px #58b5ff26;
  }
}

.Hover_Effect_2 {
  @extend %Hover_Effect;

  &:hover {
    box-shadow: 0px 4px 8px 0px #58b5ff26;
  }
}

.Hover_Effect_3 {
  @extend %Hover_Effect;

  &:hover {
    box-shadow: 0px 8px 16px 0px #58b5ff26;
  }
}

.Hover_Effect_4 {
  @extend %Hover_Effect;

  &:hover {
    box-shadow: 0px 12px 24px 0px #58b5ff26;
  }
}

.Hover_Effect_5 {
  @extend %Hover_Effect;

  &:hover {
    box-shadow: 0px 20px 32px 0px #58b5ff26;
  }
}
