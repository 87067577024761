.Monitoring_Period_Dd {
  grid-area: 4 / 1 / 5 / 2;
}

.Earliest_Date {
  grid-area: 4 / 2 / 5 / 3;
  margin-left: 3rem;

  svg {
    margin-top: 2rem;
  }
}
