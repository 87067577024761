@use "styles/variables" as *;
@use "styles/colors" as *;
@use "styles/fonts" as *;

:export {
  bgCardColor: $bg-card-color;
  fontTitleColor: $font-title-color;
  fontDisabledColor: $font-disabled-color;
  darkColor: #002848;
  lightColor: #005eaa;
  fontFamilyKarla: $font-family-karla;
  fontSizeBase: $font-size-base;
  fontSizeSmall: $font-size-sm;
  fontWeightBold: $font-weight-bold;
  fontWeightRegular: $font-weight-regular;
  lineHeight22: 22px;
  lineHeight20: 20px;
  breadcrumbsBarHeight: $breadcrumbs-bar-height;
}

.Pivot_Container {
  height: calc(100vh - 10rem);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Summary_Table {
  overflow: auto;
  height: calc(100vh - 11.9rem - #{$dashboard-tabs-bar-height} - #{$search-detail-top-bar-height}) !important;
}
