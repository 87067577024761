@use "styles/utilities" as *;
@use "styles/colors" as *;
@use "styles/variables" as *;

.Import_Card_Container {
  min-height: auto;
  min-width: 500px;
  width: 500px;

  .Import_Card_Header {
    padding-right: 5px;
    transition: all 0.3s ease;
  }

  &:hover {
    .Import_Card_Header {
      padding-right: 0px;
    }
  }
}

.Link_Wrapper {
  span {
    max-width: 200px;
    @extend .text_ellipsis;
  }
}

.Footer {
  min-height: $spacer * 9;
  box-shadow: inset 0px 1px 0px $bg-icon-color;
  display: flex;
  align-items: center;
  @extend .Bg_Card_Color;

  .Button {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    width: 100%;
  }
}
