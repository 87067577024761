@use "colors" as *;
@use "variables" as *;
@use "fonts" as *;
@use "mixins" as *;

.Table_Wrapper {
  width: 100%;
}

.Table {
  width: 100%;
  background-color: #001628;
  overflow: hidden;

  .Thead {
    @extend .Font_H9;
    @extend .Font_Disabled_Color;
    @extend .Bg_Card_Color;
    text-align: left;

    .Th {
      padding-block: 6px;
      padding-inline: 3px;
      z-index: 2;
      user-select: none;
    }

    .Th:first-child {
      padding-inline-start: $spacer * 4;
    }

    .Th:last-child {
      padding-inline-end: $spacer * 4;
    }
  }

  .Tbody {
    .Tr {
      background-color: #001628;
      border-bottom: 1px solid $bg-icon-color;
      text-align: left;
      padding: $spacer * 2 $spacer * 4;

      .Td {
        padding-block: $spacer * 2;
        padding-inline: 10px;
        white-space: nowrap;
      }

      .Td_Pills {
        display: flex;
        flex-wrap: wrap;
        max-width: 500px;
        margin: -6px;

        & > * {
          margin: 6px;
        }
      }

      .Td:first-child {
        padding-inline-start: $spacer * 4;
      }

      .Td:last-child {
        padding-inline-end: $spacer * 4;
      }
    }
    .Tr:hover {
      background: #133553;
    }
  }
}
