//*************Color variables*************//

//* Button colors

$button-primary-color: #58b5ff;
$button-hover-color: #0072cd;
$button-selected-color: #005eaa;
$button-danger-color: #e60039;

//* Background colors

$bg-tooltip-color: #001f38;
$bg-modal-color: #001424d9;
$bg-icon-color: #003560;
$bg-navbar-color: #002848;
$bg-light-base-color: #f9fafa;
$bg-card-color: #001f38;
$bg-dark-base-color: #001424;
$bg-dropdown-color: #001f38;
$bg-dropdown-border-color: #002848;
$bg-dropdown-option-hover-color: #002848;

//* Font colors

$font-title-color: #ffffff;
$font-subtitle-color: #c8c8c8;
$font-body-color: #afb4b8;
$font-disabled-color: #96a0a8;
$font-button-color: #ffffff;
$font-link-color: #58b5ff;

//* Stroke colors

$stroke-primary-color: #2f4bff;
$stroke-secondary-color: #989fae;
$stroke-disabled-color: #c0c4ce;
$stroke-white-color: #ffffff;
$stroke-seperator-color: #dfe1e7;

//* Icon colors
$icon-primary-color: #58b5ff;
$icon-secondary-color: #4b6478;
$icon-disabled-color: #004379;
$icon-white-color: #ffffff;

//* Semantic colors

$success-font-color: #00b152;
$info-font-color: #009aff;
$alert-font-color: #ffbf00;
$error-font-color: #ff4c79;

$success-bg-color: #283e32;
$info-bg-color: #133553;
$alert-bg-color: #423b24;
$error-bg-color: #462231;

$success-stroke-color: #00b152;
$info-stroke-color: #009aff;
$alert-stroke-color: #ffbf00;
$error-stroke-color: #ff4c79;

$hover-effect-color: #58b5ff26;

//****************** Font Variables ******************//

//* Font families
$font-family-lato: Lato + ", sans-serif";
$font-family-karla: Karla + ", sans-serif";

//* Font weights
$font-weight-regular: 400;
$font-weight-bold: 700;

//* Font sizes
$font-size-base: 1rem;
$font-size-4xs: $font-size-base * 0.625;
$font-size-3xs: $font-size-base * 0.688;
$font-size-2xs: $font-size-base * 0.75;
$font-size-xs: $font-size-base * 0.813;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.125;
$font-size-xl: $font-size-base * 1.313;
$font-size-2xl: $font-size-base * 1.5;
$font-size-3xl: $font-size-base * 1.75;

//***************** Padding Margin ****************//

$spacer: 0.5rem;

//* Use map-get(map, key) to get the value
$spacers: (
  0: 0,
  1: $spacer,
  2: (
    $spacer * 2,
  ),
  3: (
    $spacer * 3,
  ),
  4: (
    $spacer * 4,
  ),
  5: (
    $spacer * 5,
  ),
  6: (
    $spacer * 6,
  ),
  7: (
    $spacer * 7,
  ),
  8: (
    $spacer * 8,
  ),
  9: (
    $spacer * 9,
  ),
  10: (
    $spacer * 10,
  ),
);

$button-hover-duration: 0.1s;
$breadcrumbs-bar-height: 3.375rem;
$dashboard-tabs-bar-height: 3.75rem;
$search-detail-top-bar-height: 5rem;
