@use "styles/colors" as *;
@use "styles/fonts" as *;

.Filter {
  display: inline-block;
  width: 332px;
}

.Date_Filter_Input {
  display: inline-block;
  margin: 10px;
  width: 300px;
  margin-right: 40px;

  @extend .Bg_Light_Base_Color;
}

.Filter_Label {
  display: block;
  text-align: left;
  padding-bottom: 8px;

  @extend .Font_H13;

  // font-weight is different from the rest of the font-family
  font-weight: 700;

  @extend .Font_Subtitle_Color;
}

.React_Date_Picker_Wrapper {
  border: 1px solid !important;
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  height: 36px;
  border-radius: 3px;
}
