.Container {
  padding-left: 30px;
  margin: 20px;
  justify-content: end;

  display: grid;
  grid-template-columns: 0.1fr 1.4fr 0.6fr 0.9fr;
  grid-template-rows: 1fr;
  gap: 20px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Filters Filters_Pills Group_By Group_By_Pills"
    "Results Result_Brief_Stats Result_Brief_Stats Result_Brief_Stats"
    "Product_Type_Counts Product_Type_Counts Product_Type_Counts Product_Type_Counts";
}

.Results {
  grid-area: Results;
}

.Result_Brief_Stats {
  grid-area: Result_Brief_Stats;
}

.Product_Type_Counts {
  grid-area: Product_Type_Counts;
}
