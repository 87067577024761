@use "styles/variables" as *;
@use "styles/fonts" as *;
@use "styles/Common.module.scss" as *;

.User_Profile_Container {
  @extend .Full_Height_Flex_Container;

  flex-wrap: nowrap;
  align-items: stretch;

  .User_Profile_Left_Section {
    flex-basis: 25%;
    border-right: 1px solid $bg-icon-color;
    display: grid;
    place-items: normal center;
    margin-block: 50px;
    user-select: none;

    .User_Name_Initials_Avatar {
      @extend .Font_H11;
      overflow: clip;
      font-size: 4vw;
      width: 40%;
      aspect-ratio: 1;
      border: 1px solid $button-selected-color;
      border-radius: 50%;
      background: $bg-icon-color;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50%;
    }
  }

  .User_Profile_Right_Section {
    flex-basis: 75%;
  }
}
