@use "styles/fonts" as *;
@use "styles/mixins" as *;
@use "styles/colors" as *;

.Result_Summary_Wrapper {
  padding: 36px 30px 36px 50px;
  flex: auto;
  max-width: none;
  height: fit-content;
  overflow: auto;

  span {
    text-align: left;
    white-space: nowrap;
  }
}

.First_Level_Summary_Wrapper {
  padding: 12px 0 12px 3rem;

  .First_level {
    display: flex; 
    flex-direction: column;
    gap: 0.5rem;
  }

  .First_Level_Title {
    display: flex;
    justify-content: flex-start;
  
    @extend .Font_H3;
  }

  .First_Level_SubTitle {
    display: flex;
    justify-content: flex-start;

    @extend .Font_H8;
  }
}

.Second_Level_Summary_Wrapper {
  padding: 12px 0 12px 0;
  
  .Second_Level_Title {
    padding: 1rem 0rem 0rem 4rem;
    gap: 6rem;
    display: grid;
    grid-template-columns: 210px 210px;
    justify-content: flex-start;

    @extend .Font_H7;
  }
}

.Third_Level_Summary_Wrapper {
  padding: 6px 0 12px 0;
  

  .Third_Level_Title {
    display: grid;
    grid-template-columns: 360px 210px;
    padding: 0 0 12px 4rem;
    @extend .Font_H9;
    @extend .Font_Title_Color;

    & > span:first-of-type {
      padding-right: 5rem;
    }
  }

  .Third_Level_Verification_Title {
    display: grid;
    grid-template-columns: 230px 170px;
    padding: 0 0 12px 4rem;
    @extend .Font_H9;
    @extend .Font_Title_Color;


    & > span:first-of-type {
      padding-right: 5rem;
    }
  }
  
  .Third_Level_SubTitle{
    padding: 0 0 12px 32rem;
    display: grid;
    grid-template-columns: 150px 180px 200px 150px;

    @extend .Font_H9;
  }

  .Third_Level_Summary{
    display: grid;
    padding: 0 0 12px 45rem;
    grid-template-columns: 150px 180px 200px 150px;
    justify-content: center;

    @extend .Font_H9; 
    @extend .Font_Title_Color;
  }

  .Third_Level_Verification_Summary{
    display: grid;
    padding: 0 0 12px 40rem;
    grid-template-columns: 150px 180px 200px 150px;
    justify-content: center;

    @extend .Font_H9; 
    @extend .Font_Title_Color;
  }


}

.Fourth_Level_Summary_Wrapper {
  padding: 12px 0 12px 4rem;
  display: flex;

  & > span:first-of-type {
    padding-right: 9rem;
  }

  .Fourth_Level_Title {
    display: flex;
    justify-content: flex-start;
    width: 250px;

    margin-right: 4rem;

    @extend .Font_H8;
    @extend .Font_Subtitle_Color;
  }
}

.Detailed_Stats {
  @extend .Font_H10;
  @extend .Font_Title_Color;

  width: 20px;
  overflow: visible;
  display: inline-block;
}

.No_Result_Summary_Found {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header{
  padding: 0 0 10px 0;
  text-align: left;

  @extend .Font_Title_Color;

}
