@use "styles/variables" as *;
@use "styles/effects" as *;
@use "styles/colors" as *;

.Chart_Container {
  flex-basis: auto;
  padding-block: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 2100px;

  .Chart_Card {
    width: 80%;
    padding: 2rem 0 2rem 2rem;
    border: 1px solid $bg-icon-color;
    overflow: visible;
    border-radius: 4px;

    @extend .Bg_Card_Color;
    @extend .Hover_Effect_2;
  }
}

.Sales_Export_Button {
  display: flex;
  margin-top: 2.9rem;

  @media only screen and (max-width: 1859px) {
    margin-left: 2rem;
  }
}

.Sales_All_Export {
  @media only screen and (max-width: 1859px) {
    border-left: 1px solid #dfe1e718;
  }

  @media only screen and (min-width: 1860px) {
    border-top: 1px solid #dfe1e718;
    margin-top: 2.3rem;
  }
}

.Chart_Filter_Container {
  margin-top: 2rem;
  border-top: 1px solid #dfe1e718;
  border-right: none;
  width: 100%;
  margin-right: 2rem;
  padding-top: 1rem;
  display: flex;
  gap: 2.5rem;
  justify-content: center;
}

@media only screen and (min-width: 1860px) {
  .Chart_Filter_Container {
    border-right: 1px solid #dfe1e718;
    border-top: none;
    padding-inline: 1rem 2rem;
    padding-top: 0;
    margin-top: 1rem;
    width: auto;
    display: block;
  }
}
