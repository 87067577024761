@use "styles/variables" as *;

.Record_Data_Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 2px solid $bg-navbar-color;
  border-radius: 4px;
  width: 700px;
}

.Key_Value_Wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
  line-break: auto;
  margin: 0.7rem;
  cursor: default;

  &:not(:last-of-type) {
    border-bottom: 2px solid $bg-navbar-color;
  }
}

.Record_Data_Header {
  padding: 0.7rem;
  background-color: $info-bg-color;
}
