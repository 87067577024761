@use "styles/variables" as *;

.Text_Field_Input {
  color: $font-body-color;
  background-color: $bg-dropdown-color;
  border: 1px solid $bg-dropdown-border-color;
  width: 300px;
  padding: 0.5rem 1rem;
  outline: none;
  border-radius: 6px;

  &::placeholder {
    color: fade-out($font-body-color, 0.5);
  }

  &:disabled {
    cursor: not-allowed;
    color: $font-disabled-color;
    background-color: $bg-dark-base-color;
  }
}

.Dirty_Text_Field_Input[value=""] {
  border: 1px solid $error-font-color;

  + .Input_Text {
    position: relative;

    &::after {
      content: "This field is required";
      color: $error-font-color;
      position: absolute;
      width: 200px;
      white-space: break-word;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
