@use "styles/variables" as *;
@use "styles/fonts" as *;

// * Full height container for dashboard tabs
.Tab_Content_Flex_Container {
  min-height: calc(100vh - 16rem - #{$breadcrumbs-bar-height});
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    @extend .Font_H4;
  }
}

.Full_Height_Flex_Container {
  min-height: calc(100vh - 4rem - #{$breadcrumbs-bar-height});
  display: flex;
  justify-content: center;
  align-items: center;
}

.Full_Height_Container {
  height: calc(100vh - 140px - #{$breadcrumbs-bar-height});
  overflow-y: auto;
}

.Table_Body_Loader_Container {
  min-height: calc(100vh - 16rem - #{$breadcrumbs-bar-height});
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 99%;
}

.Table_Body_Container {
  height: calc(
    100vh - 16.9rem - #{$dashboard-tabs-bar-height} - #{$search-detail-top-bar-height}
  );
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99%;
}

.List_Scroll_Table {
  overflow-x: auto;
  height: calc(100vh - 12rem);
}

.Table_Height1 {
  height: calc(100vh - 11.4rem);
}

.Table_Height2 {
  height: calc(100vh - 19.3rem);
}

.Table_Height3 {
  height: calc(100vh - 16.1rem);
}
